import React, { useState, useEffect } from "react";
import {
    Button,
    TextField,
    Box,
    Avatar,
    Typography,
    Container,
    Modal,
    Fade,
    Backdrop,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { uploadPhoto } from "../../utility/upload";

const ProfileEdit = () => {
    const [imagePreview, setImagePreview] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const user = await Auth.currentAuthenticatedUser();
                const attributes = user?.attributes;
                setName(attributes.name || "");
                setEmail(attributes.email || "");

                if (attributes?.picture) {
                    setImagePreview(attributes.picture);
                }
            } catch (error) {
                console.error("Error fetching user data: ", error);
            }
        };

        fetchUserData();
    }, []);

    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            setImageFile(file);
            const reader = new FileReader();

            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            let imageUrl = imagePreview;

            if (imageFile) {
                const user = await Auth.currentAuthenticatedUser();
                const fileName = `${user.username}-${imageFile.name}`;

                const data = await uploadPhoto({
                    fileName,
                    file: imageFile,
                    fileDirectory: "profile-images",
                });
                imageUrl = data.Location;
            }

            const user = await Auth.currentAuthenticatedUser();
            const additionalParams = imageUrl ? { picture: imageUrl } : {};

            await Auth.updateUserAttributes(user, {
                name,
                email,
                ...additionalParams,
            });
            setModalMessage("Profile updated successfully!");
            setModalOpen(true);
        } catch (error) {
            setModalMessage(`Error updating profile: ${error.message}`);
            setModalOpen(true);
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        window.location.reload();
    };

    return (
        <Container component="main" maxWidth="xs" sx={{ mt: 13 }}>
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 3,
                    backgroundColor: "background.paper",
                    borderRadius: 1,
                    boxShadow: 3,
                }}
            >
                <Typography component="h1" variant="h5">
                    {"Edit Profile"}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        mb={2}
                    >
                        <Avatar
                            src={imagePreview}
                            alt="Profile Image"
                            sx={{ width: 100, height: 100, marginBottom: 2 }}
                        />
                        <label htmlFor="upload-image">
                            <input
                                accept="image/*"
                                style={{ display: "none" }}
                                id="upload-image"
                                type="file"
                                onChange={handleImageChange}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                component="span"
                            >
                                {"Upload Profile Image"}
                            </Button>
                        </label>
                    </Box>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Full Name"
                        name="name"
                        autoComplete="name"
                        autoFocus
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="email"
                        label="Email Address"
                        type="email"
                        id="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {"Save Changes"}
                    </Button>
                </Box>
            </Box>
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modalOpen}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 400,
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            p: 4,
                            borderRadius: 1,
                        }}
                    >
                        <Typography variant="h6" component="h2">
                            {modalMessage}
                        </Typography>
                        <Button
                            onClick={handleCloseModal}
                            sx={{ mt: 2 }}
                            variant="contained"
                            color="primary"
                        >
                            {"Close"}
                        </Button>
                    </Box>
                </Fade>
            </Modal>
        </Container>
    );
};

export default ProfileEdit;
