import React, { useState, useEffect, useContext } from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Snackbar, TextField, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { DropdownStyledTop, WrapperMid } from "./TargetVotesCalculator.styled";

import { AppContext } from "../../context/AppContext";
import AnalysisHeader from "../analysis-header/AnalysisHeader";

import { getTargetVotesCalculator } from "../../api/analysisApi";

import { POSITIONS } from "../../constants/positions";
import FullScreenSpinner from "../../components/customs/fullscreenspinner";

const columns = [
    {
        id: "region",
        label: "LOCATION",
        minWidth: 170,
    },
    {
        id: "percentage",
        label: "VOTE PERCENTAGE",
        minWidth: 120,
    },
    {
        id: "targetVotes",
        label: "TARGET VOTES",
        minWidth: 170,
    },
    {
        id: "committedVotes",
        label: "COMMITTED VOTES",
        minWidth: 170,
    },
];

const TargetVotesCalculator = ({ tabValue, handleTabChange }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [tableData, setTableData] = useState([]);
    const [committedVotes, setCommittedVotes] = useState({});
    const [totalTargetVotes, setTotalTargetVotes] = useState(0);
    const [deficiencyExcess, setDeficiencyExcess] = useState(0);

    const [loading, setLoading] = useState(false);
    const { candidate, positionId, location } = useContext(AppContext);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const formatNumberWithCommas = (number) => {
        if (number === undefined || number === null || isNaN(number)) return "";

        const valueToReturn = Math.floor(number)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return valueToReturn;
    };

    const handleCommittedVotesChange = (region, value, voted) => {
        const rawValue = value.replace(/,/g, "");
        const rawVoted = voted.replace(/,/g, "");
        const parsedValue = rawValue !== "" ? parseInt(rawValue, 10) : 0;
        const parsedVoted = voted !== "" ? parseInt(rawVoted, 10) : 0;

        if (parsedValue > parsedVoted + 1) {
            setSnackbarMessage(
                "Committed votes cannot exceed total target votes.",
            );
            setSnackbarOpen(true);

            return;
        }

        setCommittedVotes((prevVotes) => {
            const updatedVotes = {
                ...prevVotes,
                [region]: parsedValue,
            };

            let newTotalCommitted = 0;

            Object.values(updatedVotes).forEach((committedVote) => {
                newTotalCommitted += committedVote;
            });

            setDeficiencyExcess(
                parseFloat(newTotalCommitted) - parseFloat(totalTargetVotes),
            );

            return updatedVotes;
        });
    };

    const handleTargetVotes = (totalTarget) => {
        let value = parseFloat(totalTarget);

        switch (positionId) {
            case POSITIONS.president:

            case POSITIONS.vicePresident:

            case POSITIONS.governor:

            case POSITIONS.viceGovernor:

            case POSITIONS.mayor:

            case POSITIONS.viceMayor:

            case POSITIONS.houseOfRepresentatives:
                value *= 0.6;
                break;

            case POSITIONS.senator:
                value *= 0.4;
                break;

            case POSITIONS.partyList:
                value *= 0.04;
                break;
            default:
            // Do nothing
        }

        return value;
    };

    const handleSearch = async () => {
        if (!candidate || !positionId || !location) return;
        setLoading(true);

        try {
            const data = await getTargetVotesCalculator(
                candidate?.id,
                positionId,
                location,
            );

            let totalTarget = 0;
            let totalCommitted = 0;

            const formattedData = data?.calculator?.map((item) => {
                const targetVotes = Math.floor(handleTargetVotes(item.voted));
                totalTarget += targetVotes;
                totalCommitted += item.voted;

                return {
                    region: item.name,
                    percentage: `${item.percentage}%`,
                    targetVotes: formatNumberWithCommas(targetVotes),
                    committedVotes: item.votes,
                };
            });

            const initialCommittedVotes = {};

            formattedData.forEach((item) => {
                initialCommittedVotes[item.region] = item.committedVotes;
            });

            setTableData(formattedData);
            setCommittedVotes(initialCommittedVotes);
            setTotalTargetVotes(totalTarget);
            setDeficiencyExcess(totalCommitted - totalTarget);
        } catch (error) {
            console.error("Failed to fetch data", error);
            setSnackbarMessage(`Errored: ${error}`);
            setSnackbarOpen(true);
        } finally {
            setTimeout(() => setLoading(false), 1500);
        }
    };

    const getBGColor = (percentage, posId) => {
        const value = parseFloat(percentage);

        if (
            (posId === POSITIONS.president ||
                posId === POSITIONS.vicePresident ||
                posId === POSITIONS.governor ||
                posId === POSITIONS.viceGovernor ||
                posId === POSITIONS.houseOfRepresentatives ||
                posId === POSITIONS.mayor ||
                posId === POSITIONS.viceMayor) &&
            value >= 60
        ) {
            return "#e1ffee"; // Green for Single-win positions >= 60%
        }

        if (posId === POSITIONS.senator && value >= 40) {
            return "#e1ffee"; // Green for Senator >= 40%
        }

        if (posId === POSITIONS.partyList && value >= 4) {
            return "#e1ffee"; // Green for Partylist >= 4%
        }

        if (
            (posId === POSITIONS.president ||
                posId === POSITIONS.vicePresident ||
                posId === POSITIONS.governor ||
                posId === POSITIONS.viceGovernor ||
                posId === POSITIONS.houseOfRepresentatives ||
                posId === POSITIONS.mayor ||
                posId === POSITIONS.viceMayor) &&
            value >= 40 &&
            value < 60
        ) {
            return "#fff6d5"; // Yellow for Single-win positions 40% - 60%
        }

        if (posId === POSITIONS.senator && value >= 30 && value < 40) {
            return "#fff6d5"; // Yellow for Senator 30% - 40%
        }

        if (posId === POSITIONS.partyList && value >= 2 && value < 4) {
            return "#fff6d5"; // Yellow for Partylist 2% - 4%
        }

        return "#ffe1e1"; // Red for all other cases
    };

    const getColor = (percentage, posId) => {
        const value = parseFloat(percentage);

        if (
            (posId === POSITIONS.president ||
                posId === POSITIONS.vicePresident ||
                posId === POSITIONS.governor ||
                posId === POSITIONS.viceGovernor ||
                posId === POSITIONS.houseOfRepresentatives ||
                posId === POSITIONS.mayor ||
                posId === POSITIONS.viceMayor) &&
            value >= 60
        ) {
            return "#5ae258"; // Green text color for Single-win positions >= 60%
        }

        if (posId === POSITIONS.senator && value >= 40) {
            return "#5ae258"; // Green text color for Senator >= 40%
        }

        if (posId === POSITIONS.partyList && value >= 4) {
            return "#5ae258"; // Green text color for Partylist >= 4%
        }

        if (
            (posId === POSITIONS.president ||
                posId === POSITIONS.vicePresident ||
                posId === POSITIONS.governor ||
                posId === POSITIONS.viceGovernor ||
                posId === POSITIONS.houseOfRepresentatives ||
                posId === POSITIONS.mayor ||
                posId === POSITIONS.viceMayor) &&
            value >= 40 &&
            value < 60
        ) {
            return "#e1a200"; // Yellow text color for Single-win positions 40% - 60%
        }

        if (posId === POSITIONS.senator && value >= 30 && value < 40) {
            return "#e1a200"; // Yellow text color for Senator 30% - 40%
        }

        if (posId === POSITIONS.partyList && value >= 2 && value < 4) {
            return "#e1a200"; // Yellow text color for Partylist 2% - 4%
        }

        return "#f61c1c"; // Red text color for all other cases
    };

    const paginatedRows = tableData?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
    );

    return (
        <DropdownStyledTop>
            <AnalysisHeader onSearch={handleSearch} />
            {loading && <FullScreenSpinner />}
            <WrapperMid>
                <Box
                    sx={{
                        padding: "20px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "left",
                        mb: 2,
                    }}
                >
                    <Typography variant="h5" component="div">
                        {"Votes Calculator"}
                    </Typography>
                    <Box
                        sx={{
                            color: "white",
                            marginLeft: "20px",
                            display: "flex",
                            gap: 3,
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: "#46815f",
                                paddingX: "30px",
                                paddingY: "10px",
                                textAlign: "center",
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "10px",
                            }}
                        >
                            <Typography variant="h6">
                                {"Total Target Votes"}
                            </Typography>
                            <Typography
                                variant="h6"
                                sx={{ marginLeft: "15px" }}
                            >
                                {totalTargetVotes.toLocaleString()}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                backgroundColor: "#f35e82",
                                paddingX: "30px",
                                paddingY: "10px",
                                textAlign: "center",
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "10px",
                            }}
                        >
                            <Typography variant="h6">
                                {"Deficiency/Excess"}
                            </Typography>
                            <Typography
                                variant="h6"
                                sx={{ marginLeft: "15px" }}
                            >
                                {deficiencyExcess.toLocaleString()}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Paper>
                    <TableContainer
                        sx={(theme) => ({
                            fontFamily: "Open Sans, sans-serif",
                            width: "100%",
                            [theme.breakpoints.up("xl")]: {
                                width: "1410px",
                            },
                            [theme.breakpoints.up("lg")]: {
                                width: "1050px",
                            },
                            [theme.breakpoints.up("md")]: {
                                width: "850px",
                            },
                        })}
                    >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns?.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                minWidth: column.minWidth,
                                                textAlign: "center",
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedRows?.map((row) => (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.region}
                                    >
                                        {columns?.map((column) => {
                                            const isPercentageColumn =
                                                column.id === "percentage";

                                            const cellStyle = isPercentageColumn
                                                ? {
                                                      backgroundColor:
                                                          getBGColor(
                                                              row.percentage.replace(
                                                                  "%",
                                                                  "",
                                                              ),
                                                              positionId,
                                                          ),
                                                      color: getColor(
                                                          row.percentage.replace(
                                                              "%",
                                                              "",
                                                          ),
                                                          positionId,
                                                      ),
                                                      borderRadius: "2px",
                                                      textAlign: "center",
                                                      maxWidth: "100px",
                                                      fontWeight: "bold",
                                                  }
                                                : {};

                                            if (
                                                column.id === "committedVotes"
                                            ) {
                                                return (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{
                                                            textAlign: "center",
                                                            padding: "3px",
                                                        }}
                                                    >
                                                        <TextField
                                                            type="text"
                                                            value={
                                                                formatNumberWithCommas(
                                                                    committedVotes[
                                                                        row
                                                                            .region
                                                                    ],
                                                                ) || ""
                                                            }
                                                            onChange={(e) =>
                                                                handleCommittedVotesChange(
                                                                    row.region,
                                                                    e.target
                                                                        .value,
                                                                    row.targetVotes,
                                                                )
                                                            }
                                                            inputProps={{
                                                                inputMode:
                                                                    "numeric",
                                                                pattern:
                                                                    "[0-9]*",
                                                            }}
                                                            style={{
                                                                textAlign:
                                                                    "right",
                                                            }}
                                                        />
                                                    </TableCell>
                                                );
                                            }

                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={cellStyle}
                                                >
                                                    {row[column.id] || ""}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={tableData?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </WrapperMid>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity="error"
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </DropdownStyledTop>
    );
};

export default TargetVotesCalculator;
