import AWS from "aws-sdk";
import awsconfig from "../configs/aws-config";

export const uploadPhoto = async ({ fileName, file, fileDirectory }) => {
    const S3_BUCKET = awsconfig.aws_storage_bucket;
    const REGION = awsconfig.aws_storage_region;

    const s3 = new AWS.S3({
        region: REGION,
    });

    const params = {
        Bucket: `${S3_BUCKET}/${fileDirectory}`,
        Key: fileName,
        Body: file,
    };

    try {
        const data = await s3.upload(params).promise();

        return data;
    } catch (err) {
        console.error("Error uploading file to S3: ", err);
        throw err;
    }
};
