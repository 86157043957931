import React, { useState, useEffect, useContext, useMemo } from "react";

import Snackbar from "@mui/material/Snackbar";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import AnalysisHeader from "../analysis-header/AnalysisHeader";

import {
    DropdownStyledTop,
    WrapperMid,
    TextTitle,
    HeaderContainer,
} from "./WinnersPerformance.styled";
import { AppContext } from "../../context/AppContext";
import { getWinnersPerformance } from "../../api/analysisApi";
import FullScreenSpinner from "../../components/customs/fullscreenspinner";

const columns = [
    {
        id: "locationName",
        minWidth: 170,
    },
    {
        id: "strongest",
        minWidth: 170,
    },
    {
        id: "average",
        minWidth: 170,
    },
    {
        id: "weakest",
        minWidth: 170,
    },
];

const WinnersPerformance = ({ tabValue, handleTabChange }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [originalData, setOriginalData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [sortOption, setSortOption] = useState(null);

    const [loading, setLoading] = useState(false);
    const { positionId, location } = useContext(AppContext);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSort = (option) => {
        setSortOption(option);
    };
    const handleSearch = async () => {
        if (!positionId || !location) return;
        setLoading(true);

        try {
            const data = await getWinnersPerformance(positionId, location);

            const formattedData = data?.performance.map((item) => {
                let strongest = "";
                let average = "";
                let weakest = "";

                const margin = parseFloat(item.margin);

                if (margin <= 10) {
                    weakest = `${item.firstName} ${item.lastName}`;
                } else if (margin < 30) {
                    average = `${item.firstName} ${item.lastName}`;
                } else {
                    strongest = `${item.firstName} ${item.lastName}`;
                }

                return {
                    locationName: item.locationName,
                    strongest,
                    average,
                    weakest,
                    margin: item.margin,
                };
            });

            setOriginalData(formattedData ?? []);
            setFilteredData(formattedData ?? []);
        } catch (error) {
            console.error("Failed to fetch data", error);
            setSnackbarMessage(`Errored: ${error}`);
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const filtered = originalData.filter((row) =>
            row.locationName?.toLowerCase().includes(searchQuery.toLowerCase()),
        );

        setPage(0);
        setFilteredData(filtered);
    }, [searchQuery, originalData]);

    const paginatedRows = filteredData.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
    );

    const sortedRows = useMemo(() => {
        if (!sortOption) return paginatedRows;

        return [...paginatedRows].sort((a, b) => {
            const marginA = parseFloat(a.margin) || 0;
            const marginB = parseFloat(b.margin) || 0;

            if (sortOption === "strongest") {
                return marginB - marginA; // Higher margin first
            }

            if (sortOption === "weakest") {
                return marginA - marginB; // Lower margin first
            }

            if (sortOption === "average") {
                const isAMidRange = marginA >= 10 && marginA < 30;
                const isBMidRange = marginB >= 10 && marginB < 30;

                if (isBMidRange && !isAMidRange) return -1;
                if (isAMidRange && !isBMidRange) return 1;
                return marginB - marginA;
            }

            return 0;
        });
    }, [sortOption, paginatedRows]);

    return (
        <DropdownStyledTop>
            <AnalysisHeader onSearch={handleSearch} />
            {loading && <FullScreenSpinner />}
            <WrapperMid>
                <Paper sx={{ borderRadius: 10 }}>
                    <TableContainer
                        sx={(theme) => ({
                            fontFamily: '"Open Sans", sans-serif',
                            width: "90vw",
                            marginTop: "30px",
                            [theme.breakpoints.up("lg")]: {
                                minWidth: "70rem",
                            },
                            [theme.breakpoints.up("xl")]: {
                                minWidth: "93rem",
                            },
                        })}
                        className="table-container"
                    >
                        <HeaderContainer>
                            <TextTitle sx={{ marginTop: 2, marginLeft: 2 }}>
                                {"Winners Performance"}
                            </TextTitle>

                            <Box
                                sx={{
                                    width: 350,
                                    paddingRight: 5,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <TextField
                                    disabled={!positionId || !location}
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={(e) =>
                                        setSearchQuery(e.target.value)
                                    }
                                    InputProps={{
                                        type: "search",
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth
                                />
                            </Box>
                        </HeaderContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{ fontSize: 16, color: "#46815F" }}
                                        align="center"
                                        colSpan={1}
                                    >
                                        {"AREA"}
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontSize: 16 }}
                                        align="left"
                                        colSpan={1}
                                    >
                                        <Box
                                            sx={{
                                                background: "#6AD59B",
                                                borderRadius: 5,
                                                display: "inline-block",
                                                px: 3,
                                                py: 1,
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                handleSort("strongest");
                                            }}
                                        >
                                            {"STRONGEST"}
                                        </Box>
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontSize: 16 }}
                                        align="left"
                                        colSpan={1}
                                    >
                                        <Box
                                            sx={{
                                                background: "#FFCF26",
                                                borderRadius: 5,

                                                display: "inline-block",
                                                px: 3,
                                                py: 1,
                                                cursor: "pointer",
                                            }}
                                            onClick={() =>
                                                handleSort("average")
                                            }
                                        >
                                            {"AVERAGE"}
                                        </Box>
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontSize: 16 }}
                                        align="left"
                                        colSpan={1}
                                    >
                                        <Box
                                            sx={{
                                                background: "#F35E82",
                                                borderRadius: 5,
                                                color: "#FFFFFF",
                                                display: "inline-block",
                                                px: 3,
                                                py: 1,
                                                cursor: "pointer",
                                            }}
                                            onClick={() =>
                                                handleSort("weakest")
                                            }
                                        >
                                            {"WEAKEST"}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                minWidth: column.minWidth,
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedRows &&
                                    sortedRows.length > 0 &&
                                    sortedRows.map((row) => (
                                        <TableRow key={row.locationName}>
                                            {columns &&
                                                columns.map((column) => {
                                                    const value =
                                                        row[column.id];
                                                    return (
                                                        <TableCell
                                                            key={column.id}
                                                        >
                                                            {value !== undefined
                                                                ? value
                                                                : "-"}
                                                        </TableCell>
                                                    );
                                                })}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </WrapperMid>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity="error"
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </DropdownStyledTop>
    );
};

export default WinnersPerformance;
