import React, { useState, useContext, useEffect } from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";

import AnalysisHeader from "../analysis-header/AnalysisHeader";
import { DropdownStyledTop, WrapperMid } from "./ComparisonAnalysis.styled";
import { AppContext } from "../../context/AppContext";
import { getComparisonAnalysis } from "../../api/analysisApi";

import LossImage from "../../assets/img/loss.png";
import WonImage from "../../assets/img/won.png";
import FullScreenSpinner from "../../components/customs/fullscreenspinner";

const columns = [
    {
        id: "candidatePercent",
        label: "Candidate Vote %",
        minWidth: 170,
        align: "center",
    },
    { id: "location", label: "Location", minWidth: 170, align: "center" },
    {
        id: "opponentPercent",
        label: "Opponent Vote %",
        minWidth: 170,
        align: "center",
    },
];

function createData(location, candidatePercent, opponentPercent) {
    return { location, candidatePercent, opponentPercent };
}

const getCellStyles = (value) => {
    const numericValue = parseFloat(value);
    const defaultStyle = { fontWeight: "600" };

    if (numericValue >= 60) {
        return {
            ...defaultStyle,
            backgroundColor: "#e1ffee",
            color: "#29c684",
        }; // High values
    }

    if (numericValue >= 40 && numericValue < 60) {
        return {
            ...defaultStyle,
            backgroundColor: "#fff6d5",
            color: "#e3bc35",
        }; // Middle values
    }

    if (numericValue < 40) {
        return {
            ...defaultStyle,
            backgroundColor: "#ffe1e1",
            color: "#e60202",
        }; // Low values
    }

    return {};
};

const ComparisonAnalysis = ({ tabValue, handleTabChange }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const { candidate, candidate2, location } = useContext(AppContext);
    const [candidatesResult, setCandidateResult] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        if (!candidate || !candidate2 || !location) {
            setTableData([]);
        }
    }, [candidate, candidate2, location]);

    const handleSearch = async () => {
        if (!candidate || !candidate2 || !location) {
            return;
        }
        setLoading(true);

        try {
            const data = await getComparisonAnalysis(
                candidate?.id,
                candidate2?.id,
                location,
            );

            const formattedData = data?.comparison?.map((item) =>
                createData(
                    item.Location,
                    item.CandidateVotePercent
                        ? `${item.CandidateVotePercent}%`
                        : "N/A",
                    item.OpponentVotePercent
                        ? `${item.OpponentVotePercent}%`
                        : "N/A",
                ),
            );

            setCandidateResult(data?.national);
            setTableData(formattedData);
        } catch (error) {
            console.error("Failed to fetch data", error);
            setSnackbarMessage(`Errored: ${error}`);
            setSnackbarOpen(true);
        } finally {
            setTimeout(() => setLoading(false), 1000);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const paginatedRows = tableData.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
    );

    const renderComparisonPoliticians = () => {
        const winnerBG = {
            background: "linear-gradient(90deg, #e1ffee, #a6edbd)",
        };

        const losserBG = {
            background: "linear-gradient(90deg, #ffe2e2, #fbbbc5)",
        };

        const candidatePercentage = parseFloat(
            candidatesResult?.CandidateVotePercent,
        );
        const opponentPercentage = parseFloat(
            candidatesResult?.OpponentVotePercent,
        );
        const isCandidateWinner = candidatePercentage > opponentPercentage;
        const isOpponentWinner = opponentPercentage > candidatePercentage;

        const candidateBG = isCandidateWinner ? winnerBG : losserBG;
        const opponentBG = isOpponentWinner ? winnerBG : losserBG;

        const candidateImage = isCandidateWinner ? WonImage : LossImage;
        const opponentImage = isOpponentWinner ? WonImage : LossImage;

        const candidateProgressColor = isCandidateWinner
            ? "secondary"
            : "error";
        const opponentProgressColor = isOpponentWinner ? "secondary" : "error";

        return (
            <Box
                sx={(theme) => ({
                    fontFamily: "Open Sans, sans-serif",
                    gap: "48px",
                    width: "100%",
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",

                    [theme.breakpoints.up("md")]: {
                        minWidth: "60rem",
                        maxWidth: "60rem",
                    },

                    [theme.breakpoints.up("lg")]: {
                        minWidth: "93rem",
                        maxWidth: "93rem",
                    },
                })}
            >
                <Box
                    sx={{
                        minWidth: "45vw",
                        fontWeight: "bold",
                        padding: "20px",
                        borderRadius: "20px",
                        border: "1px solid #c1c1c1",
                        position: "relative",
                        ...candidateBG,
                    }}
                >
                    <Box sx={{ fontSize: "20px" }}>{candidate?.fullName}</Box>
                    <Box sx={{ fontSize: "30px" }}>
                        {candidatesResult?.CandidateVotePercent}
                        {"%"}
                    </Box>
                    <LinearProgress
                        sx={{ maxWidth: "200px" }}
                        color={candidateProgressColor}
                        variant="determinate"
                        value={candidatesResult?.CandidateVotePercent}
                    />
                    <Box sx={{ fontSize: "12px", fontWeight: "300" }}>
                        {candidate?.positionName}
                    </Box>
                    <Box
                        component="img"
                        sx={{
                            width: "100px",
                            height: "auto",
                            right: "10px",
                            top: "10px",
                            position: "absolute",
                        }}
                        alt="Descriptive Alt Text"
                        src={candidateImage}
                    />
                </Box>
                <Box
                    sx={{
                        position: "absolute",
                        fontWeight: "600",
                        fontSize: "20px",
                        color: "white",
                        backgroundColor: "#46815f",
                        top: "30px",
                        padding: "18px 22px",
                        margin: "auto",
                        zIndex: "500",
                        borderRadius: "100%",
                    }}
                >
                    {"VS"}
                </Box>
                <Box
                    sx={{
                        minWidth: "45vw",
                        fontWeight: "bold",
                        padding: "20px",
                        borderRadius: "20px",
                        border: "1px solid #c1c1c1",
                        position: "relative",
                        ...opponentBG,
                    }}
                >
                    <Box sx={{ fontSize: "20px" }}>{candidate2?.fullName}</Box>
                    <Box sx={{ fontSize: "30px" }}>
                        {candidatesResult?.OpponentVotePercent}
                        {"%"}
                    </Box>
                    <LinearProgress
                        sx={{ maxWidth: "200px" }}
                        color={opponentProgressColor}
                        variant="determinate"
                        value={candidatesResult?.OpponentVotePercent}
                    />
                    <Box sx={{ fontSize: "12px", fontWeight: "300" }}>
                        {candidate2?.positionName}
                    </Box>
                    <Box
                        component="img"
                        sx={{
                            width: "100px",
                            height: "auto",
                            right: "10px",
                            top: "10px",
                            position: "absolute",
                        }}
                        alt="Descriptive Alt Text"
                        src={opponentImage}
                    />
                </Box>
            </Box>
        );
    };

    return (
        <DropdownStyledTop>
            <AnalysisHeader
                tabValue={tabValue}
                handleTabChange={handleTabChange}
                onSearch={handleSearch}
            />
            {loading && <FullScreenSpinner />}
            {tableData &&
                tableData?.length > 0 &&
                renderComparisonPoliticians()}
            <WrapperMid>
                <Paper>
                    <TableContainer
                        sx={(theme) => ({
                            fontFamily: "Open Sans, sans-serif",
                            width: "100%",
                            [theme.breakpoints.up("xl")]: {
                                width: "1450px",
                            },
                            [theme.breakpoints.up("lg")]: {
                                width: "1100px",
                            },
                            [theme.breakpoints.up("md")]: {
                                width: "950px",
                            },
                        })}
                    >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                minWidth: column.minWidth,
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedRows.map((row) => (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.location}
                                    >
                                        <TableCell
                                            align="center"
                                            sx={getCellStyles(
                                                row.candidatePercent,
                                            )}
                                        >
                                            {row.candidatePercent}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{ fontWeight: "600" }}
                                        >
                                            {row.location}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={getCellStyles(
                                                row.opponentPercent,
                                            )}
                                        >
                                            {row.opponentPercent}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={tableData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </WrapperMid>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity="error"
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </DropdownStyledTop>
    );
};

export default ComparisonAnalysis;
